import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useSearchParams } from 'react-router-dom';
import cloudbase from '@cloudbase/js-sdk';
import wx from 'weixin-js-sdk'

const app = cloudbase.init({
  env: 'nfit-minipg-0gw87w5va99b194f',
});
console.log('app state', app);

const auth = app.auth({
  persistence: "none" //用户显式退出或更改密码之前的30天一直有效
});
console.log('auth state', auth);

// const wx = window.jWeixin;
// const wx2 = window.wx;
console.log("Dep state", wx);
// console.log("Dep state2", wx2);


const RedirectWithParams = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = "https://auth.h5.dinglian.biz/loading?appid=M78R97sYoGWvTvpMLc&chain_id=conflux&scope=walletAddress&redirect_uri=https://auth-dingblock.nfitsh.xyz/handle-redirect&type=oauth";
  const otherParam = searchParams.get('otherParam');

  useEffect(() => {
    if (redirectUrl) {
      const confirmRedirect = window.confirm('即将前往盯链认证页面，是否继续？');
      if (confirmRedirect) {
        window.location.href = `${redirectUrl}`;
      }
    } else {
      // Alert the user that redirectUrl or otherParam is not provided
      alert('Missing redirectUrl or otherParam. Please provide both.');
    }
  }, [redirectUrl, otherParam]);

  return null;
}

const callTCloudfunction = async (code) => {
  console.log('callTCloudfunction');

  return app.callFunction({
    name: 'connectDingblock',
    data: {
      code: code
    }
  }).then((res) => {
    console.log('res', res);
    return res;
  }).catch((err) => {
    console.log('err', err);
    throw err;
  });
}

// const HandleRedirect = () => {
//   if (window.__wxjs_environment === 'miniprogram') {
//     console.log('open in miniprogram');
//     // const [searchParams] = useSearchParams();
//     // const code = searchParams.get('code');
//     // const state = searchParams.get('state');

//     // Now you can use param1 and param2 in your component
//     // ...
//     const loginState = app.auth().hasLoginState();

//     if (loginState) {
//       // 登录态有效
//       console.log('loginState', loginState);
//     } else {
//       // 没有登录态，或者登录态已经失效
//       console.log('no loginState', loginState);
//     }
//     return <div>Handle redirect</div>;
//   } else {
//     return <div>请在微信小程序中打开本页面</div>;
//   }
// }

const HandleRedirect = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [res, setRes] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await callTCloudfunction(code);
  
        wx.miniProgram.postMessage({
          data: {
            address: result,
          }
        });
  
        wx.miniProgram.switchTab({
          url: '/pages/me/index'
        });
  
        setRes(result);
      } catch (error) {
        console.error('Failed to call TCloud function:', error);
        // Handle the error appropriately here
      }
    };
    fetchData();
  }, [code]);
  console.log("res", res);
  return <div>正在绑定中...{res}</div>;
}

const NotFound = () => <div>Not found</div>;

const App = () => (
  <Router>
    <Routes>
      <Route path="/redirect" element={<RedirectWithParams />} />
      <Route path="/handle-redirect" element={<HandleRedirect />} />
      <Route path="/" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

export default App;
